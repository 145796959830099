import React from 'react';
import { ImageForm } from 'src/components/image-form';
import { VehiclesPage } from 'src/components/pages/VehiclesPage';
import { VehiclePage } from 'src/components/pages/VehiclePage';
import { BrandsPage } from 'src/components/pages/BrandsPage';
import { ModelsPage } from 'src/components/pages/ModelsPage';
import { addImagesToProduct, addImagesToStockItem } from 'src/api/imagesApi';
import { ProductsAnalogsForm } from 'src/components/products-analogs-form';
import { EnginesPage } from 'src/components/pages/EnginesPage';
import { BodiesPage } from 'src/components/pages/BodiesPage';
import { RestylesPage } from 'src/components/pages/RestylesPage';
import { ProductPage } from 'src/components/pages/ProductPage';
import { StockItemPage } from 'src/components/pages/StockItemPage';
import { StockItemsPage } from 'src/components/pages/StockItemsPage';
import { SupplierPage } from 'src/components/pages/SupplierPage';
import { WarehousesPage } from 'src/components/pages/WarehousesPage';
import { SuppliersPage } from 'src/components/pages/SuppliersPage';
import { ScenariosPage } from 'src/components/pages/ScenariosPage';
import { AccountsPage } from 'src/components/pages/AccountsPage';
import { BillingJournalReasonsPage } from 'src/components/pages/BillingJournalReasonsPage';
import { BillingJournalPage } from 'src/components/pages/BillingJournalPage';
import { OrdersPage } from 'src/components/pages/OrdersPage';
import { OrderPage } from 'src/components/pages/OrderPage';
import { DepricatedSearchPage } from 'src/components/pages/DepricatedSearchPage';
import { AccountPage } from 'src/components/pages/AccountPage';
import { ProductTypesPage } from 'src/components/pages/ProductTypesPage';
import { ProductTypePage } from 'src/components/pages/ProductTypePage';
import { StickersPage } from 'src/components/pages/StickersPage';
import { LabelsPrintPage } from 'src/components/pages/LabelsPrintPage';
import { DeliveryCompaniesPage } from 'src/components/pages/DeliveryCompaniesPage';
import { DeliveriesPage } from 'src/components/pages/DeliveriesPage';
import { DeliveriesPrint } from 'src/components/pages/DeliveriesPrint';
import { DiagnosticsPage } from 'src/components/pages/DiagnosticsPage';
import { EmployeesPage } from 'src/components/pages/EmployeesPage';
import { EmployeePage } from './components/pages/EmployeePage';
import { AttributesPage } from './components/pages/AttributesPage';
import { DiagnosticPage } from './pages/diagnostic';

export const routes = [
  {
    path: '/depricated_search',
    component: <DepricatedSearchPage />,
  },
  {
    path: '/orders/:orderId',
    component: <OrderPage />,
  },
  {
    path: '/orders',
    component: <OrdersPage />,
    title: 'Заказы',
  },
  {
    path: '/deliveries',
    component: <DeliveriesPage />,
    title: 'Доставки',
  },
  {
    path: '/billing-journal',
    component: <BillingJournalPage />,
    title: 'Операции',
  },
  {
    path: '/accounts/:accountId',
    component: <AccountPage />,
  },
  {
    path: '/accounts',
    component: <AccountsPage />,
    title: 'Счета',
  },
  {
    path: '/employees',
    component: <EmployeesPage />,
    title: 'Сотрудники',
  },
  {
    path: '/billing-journal-reasons',
    component: <BillingJournalReasonsPage />,
    title: 'Основания движения денег',
  },
  {
    path: '/stock-items/:stockItemId',
    component: <StockItemPage />,
  },
  {
    path: '/scenarios',
    title: 'Сценарии экспорта',
    component: <ScenariosPage />,
  },
  {
    path: '/stickers',
    component: <StickersPage />,
  },
  {
    path: '/stock-items',
    component: <StockItemsPage />,
    title: 'Товары',
  },
  { path: '/', component: <VehiclesPage /> },
  { path: '/analogs', component: <ProductsAnalogsForm /> },
  {
    path: '/images',
    component: (
      <>
        <ImageForm
          onAdd={files => addImagesToProduct({ productId: 1, files })}
          id="attachProductImages"
        />
        <ImageForm
          onAdd={files => addImagesToStockItem({ stockItemId: 1, files })}
          id="attachItemImages"
        />
      </>
    ),
  },
  {
    path: '/suppliers/:supplierId',
    component: <SupplierPage />,
  },
  {
    path: '/warehouses',
    component: <WarehousesPage />,
    title: 'Склады',
  },
  {
    path: '/suppliers',
    component: <SuppliersPage />,
    title: 'Поставщики',
  },
  {
    path: '/product-types/:typeId',
    component: <ProductTypePage />,
  },
  {
    path: '/product-types',
    component: <ProductTypesPage />,
    title: 'Типы товаров',
  },
  {
    path: '/brands',
    component: <BrandsPage />,
    title: 'Бренды',
  },
  {
    path: '/vehicles',
    component: <VehiclesPage />,
    title: 'Автомобили',
  },

  {
    path: '/vehicles/:vehicleId',
    component: <VehiclePage />,
  },
  {
    path: '/models',
    component: <ModelsPage />,
    title: 'Модели',
  },
  {
    path: '/bodies',
    component: <BodiesPage />,
    title: 'Кузова',
  },
  {
    path: '/engines',
    component: <EnginesPage />,
    title: 'Двигатели',
  },
  {
    path: '/restyles',
    component: <RestylesPage />,
    title: 'Рестайлинги',
  },
  {
    path: '/products/:productId',
    component: <ProductPage />,
  },
  {
    path: '/labels-print',
    component: <LabelsPrintPage />,
  },
  {
    path: '/delivery-companies',
    component: <DeliveryCompaniesPage />,
    title: 'Транспортные компании',
  },
  {
    path: '/deliveries-print',
    component: <DeliveriesPrint />,
  },
  {
    path: '/attributes',
    component: <AttributesPage />,
    title: 'Атрибуты',
  },
  {
    path: '/diagnostics',
    component: <DiagnosticsPage />,
    title: 'Диагностики',
  },
  {
    path: '/diagnostics/:diagnosticId',
    component: <DiagnosticPage />,
  },
  {
    path: '/employees/:employeeId',
    component: <EmployeePage />,
  },
];
