export type SupplierMarkupRange = {
  id?: number;
  price: number;
  markup: number;
};

export interface Supplier {
  id?: number;
  name?: string;
  alias?: string;
  markupRanges?: SupplierMarkupRange[];
}

export interface ProductName {
  id?: number;
  value?: string | null;
  supplier?: Supplier;
  product?: Product;
}

export interface Brand {
  id?: number;
  isCarManufacturer: boolean;
  originalName: string;
}

export interface ProductComponent {
  id: number;
  product: Product;
  component: Product;
}

export interface Warehouse {
  id?: number;
  name?: string;
  alias?: string;
  deprecatedId?: number;
}

export interface WarehousePlace {
  id?: number;
  name?: string;
  warehouse?: Warehouse;
}

export interface Model {
  id?: number;
  name: string;
  brand: Brand;
}

export interface Body {
  id?: number;
  name: string;
  model: Model;
}

export interface Engine {
  id?: number;
  name: string;
  bodies: Body[];
}

export interface Restyle {
  id?: number;
  name: string;
  body: Body;
  yearStart: number | null;
  yearEnd: number | null;
}

export interface Image {
  id: number;
  publicPath: string;
  width?: number;
  height?: number;
}

export interface VehicleCost {
  id: number;
  value: number;
  comment: number | number;
  reason: string;
  reasonName: string;
  exchangeRate: number;
  cost: number;
}

export interface OrderElement {
  id?: number;
  stockItem?: StockItem | null;
  startPrice?: number;
  offerPrice?: number;
  totalCost?: number;
  quantity?: number;
  orderRealizationElement?: OrderRealizationElement | null;
  orderToSupplierElement?: OrderToSupplierElement | null;
  orderElementReturns?: OrderElementRetrun[] | null;
}

export interface OrderElementRetrun {
  id?: number;
  createdAt?: string;
  orderElement?: OrderElement | null;
  quantity?: number;
}

export interface OrderToSupplierElement {
  id?: number;
  orderElement?: OrderElement | null;
  startPrice?: number;
  totalCost?: number;
  requestedQuantity?: number;
  providedQuantity?: number | null;
}

export interface OrderRealizationElement {
  id?: number;
  orderElement?: OrderElement | null;
  offerPrice?: number;
  totalCost?: number;
  quantity?: number;
  createdAt?: number;
}

export type OrderRealization = {
  id?: number;
  totalCost?: number;
  createdAt?: string;
  hash?: string;
  elements?: OrderRealizationElement[] | null;
};

export interface OrderToSupplier {
  id?: number;
  totalCost?: number;
  createdAt?: string;
  elements?: OrderRealizationElement[] | null;
  realizations?: OrderRealization[] | null;
  isAllProvided?: boolean;
}

export interface OrderCustomerSource {
  id?: number;
  name?: string;
}

export interface OrderSource {
  id?: number;
  name?: string;
}

export type DeliveryCompany = {
  id: number;
  name: string;
  isEnabled: boolean;
};

export interface Delivery {
  id?: number;
  createdAt?: string;
  deliveryCompany: DeliveryCompany | null;
  phone: string | number | null;
  secondName: string | null;
  firstName: string | null;
  middleName: string | null;
  fullName?: string | null;
  address: string | null;
  comment: string | null;
  cost: number;
  packageCost: number;
  insurance: number;
  customer?: Customer | null;
  finishedAt?: string | null;
  passportCode?: string | null;
  orders?: Order[];
  isPackage?: boolean;
}

export interface Order {
  id?: number;
  totalCost?: number;
  remainingCost?: number;
  paymentsCost?: number;
  returnsCost?: number;
  createdAt?: string;
  contractor?: Contractor | null;
  elements?: OrderElement[] | null;
  ordersToSuppliers?: OrderToSupplier[] | null;
  realizations?: OrderRealization[] | null;
  customer?: Customer | null;
  comment?: string | null;
  privateComment?: string | null;
  orderCustomerSource?: OrderCustomerSource | null;
  orderSource?: OrderSource | null;
  delivery?: Delivery | null;
  hash?: string;
}

export interface PhoneNumber {
  id?: number;
  value?: string | null;
}

export interface Customer {
  id?: number;
  firstName?: string | null;
  middleName?: string | null;
  secondName?: string | null;
  fullName?: string | null;
  organizationName?: string | null;
  phone?: PhoneNumber | null;
  orders?: Order[];
  ordersCount?: number | null;
}

export interface Contractor {
  id?: number;
  alias?: string;
  name?: string;
}

export interface Vehicle {
  id?: number | null;
  description?: string | null;
  createdAt?: string | null;
  mileage?: number | null;
  engine?: Engine | null;
  body?: Body | null;
  restyle?: Restyle | null;
  year?: number | null;
  totalCost?: number | null;
  frame?: string | null;
  colorName?: string | null;
  colorCode?: string | null;
  name?: string | null;
  images?: Image[] | null;
  stockItems?: StockItem[];
  arrival?: string | null;
  ruName?: string | null;
  isArrived?: boolean;
  costs?: VehicleCost[];
  customer?: Customer | null;
  contractor?: Contractor | null;
}

export interface StockItem {
  id: number;
  startPrice: number | null;
  fixedPrice: number | null;
  offerPrice: number | null;
  quantity: number;
  product?: Product | null;
  title: string | null;
  description?: string | null;
  itemName?: string | null;
  itemNameWithPosition?: string | null;
  warehousePlace: WarehousePlace | null;
  vehicle?: Vehicle | null;
  images?: Image[] | null;
  warehouse?: Warehouse | null;
  owner?: Supplier | null;
  ordersElements?: OrderElement[];
  characteristics?: Characteristic[];
}
export interface ProductUsage {
  id: number;
  model: Model | null;
  product: Product;
  body: Body | null;
  engine: Engine | null;
}

export interface ProductAnalog {
  id: number;
  product: Product;
  analog: Product;
  source: Supplier;
  createdAt: string;
}
export interface Characteristic {
  attribute: Attribute;
  value: number | boolean | { value: string };
  humanFriendlyValue?: string;
}

export interface Product {
  id: number;
  brand: Brand;
  article: string;
  articleCanonical: string;
  name: ProductName | null;
  description?: string | null;
  nameValue: string | null;
  nameWithPosition: string | null;
  articleCanonicalPublic?: string | null;
  components: ProductComponent[];
  mainComponent: ProductComponent | null;
  stockItems: StockItem[];
  images?: Image[] | null;
  topBottom: string | null;
  frontRear: string | null;
  leftRight: string | null;
  usage: ProductUsage[];
  analogs: ProductAnalog[];
  type: ProductType | null;
  characteristics: Characteristic[];
  country?: Country | null;
  series?: Series | null;
  extendedName?: string | null;
}

export interface ProductTypeAlias {
  id: number;
  name?: string;
  nameCanonical?: string;
  type?: ProductType | null;
}

export enum ProductTypeAttributeType {
  'numeric' = 'numeric',
  'string' = 'string',
  'bool' = 'bool',
}

interface StringAttributeValue {
  id: number;
  value: string;
}

export interface Attribute {
  id: number;
  name: string;
  type: string;
  listPossibleValues?: StringAttributeValue[];
}

export interface ProductTypeCategory {
  id: number;
  name: string;
  code: string;
}

export interface ProductType {
  id?: number;
  name?: string;
  aliases?: ProductTypeAlias[];
  products?: Product[];
  targetsTypes?: ProductType[];
  target?: ProductType | null;
  category?: ProductTypeCategory | null;
  targetsTypesCount?: number;
  aliasesCount?: number;
  gender?: number | null;
  attributes?: Attribute[];
}

export interface Country {
  id: number;
  name: string;
}

export interface Series {
  id: number;
  name: string;
}

export interface STOWorkPrice {
  id: number;
  comment: string | null;
  price: number | null;
  model: Model | null;
  body: Body | null;
  engine: Engine | null;
  stoWork?: STOWork;
}

export interface STOWork {
  id: number;
  name: string;
  comment: string | null;
  prices: STOWorkPrice[];
}

export interface DiagnosticsElement {
  id: number;
  price: number;
  quantity: number;
  comment: string | null;
  work: STOWork | null;
}

export interface Diagnostics {
  id: number;
  createdAt: string;
  customer: Customer;
  vehicle?: Vehicle | null;
  elements: DiagnosticsElement[];
}
